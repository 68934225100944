<template>
  <b-modal
    id="import-user-modal"
    :title="$t('users.import_users')"
    centered
    size="md"
    :ok-title="$t('courses.import')"
    :cancel-title="$t('general.cancel')"
    no-close-on-backdrop
    @ok.prevent="onSaveFile"
  >
    <validation-observer ref="observer">
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('users.file_for_import')"
            label-for="import-file"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('users.file_for_import')"
              rules="required"
            >
              <b-form-file
                id="import-file"
                v-model="file"
                accept=".xls, .xlsx"
                :browse-text="$t('general.upload')"
                :placeholder="$t('general.select_file')"
                :drop-placeholder="$t('general.select_file')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormFile,
} from 'bootstrap-vue'
import { required } from '@core/utils/validations/validations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { mapActions } from 'vuex'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    defaultParams: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      file: null,
      required,
    }
  },
  methods: {
    ...mapActions('users', ['IMPORT_USERS_FROM_FILE', 'FETCH_USERS_LIST']),
    onSaveFile() {
      this.$refs.observer.validate()
        .then(success => {
          if (!success) return
          const data = new FormData()
          data.append('file', this.file)
          this.IMPORT_USERS_FROM_FILE(data)
            .then(() => {
              this.file = null
              this.FETCH_USERS_LIST(this.defaultParams)
              this.$bvModal.hide('import-user-modal')
              this.$_successToast(this.$t('general.success'))
            })
            .catch(e => {
              if (e.response.data.username) {
                this.$_errorToast(e.response.data.username)
              } else {
                this.$_errorToast(this.$t('general.error'))
              }
            })
        })
    },
  },
}
</script>

<style>

</style>
