<template>
  <div>
    <!-- <HeadCards /> -->
    <full-table
      :other-filter-props="{
        add:
          $can('manage', 'id_manager') ||
          $can('manage', 'admin') ||
          $can('manage', 'super_admin') ||
          $can('manage', 'head_manager'),
        search: true,
        gender: true,
        role: true,
        importButton: true,
        exportExcel: true,
        tags: true,
        branchs:
          $can('manage', 'super_admin') || $can('manage', 'head_manager'),
      }"
      :items="GET_USERS_LIST.results"
      :fields="fields"
      :rows="GET_USERS_LIST.count"
      :edit-btn="false"
      :first-params="params"
      :empty-text="$t('general.no_data_to_view')"
      @import="importUsers"
      @export="exportUsers"
      @refresh="FETCH_USERS_LIST"
      @row-clicked="(data) => $router.push(`/users/${data.id}/info`)"
      @detail-button-clicked="(id) => $router.push(`/users/${id}/info`)"
      @delete-button-clicked="modalDeleteUser"
      @add-button-clicked="addUser"
    />
    <add-modal :default-params="params" />
    <import-user-modal :default-params="params" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import toast from '@/mixins/toast'
import areYouSure from '@/mixins/areYouSure'
// import HeadCards from '@/components/HeadCards.vue'
import FullTable from '@/components/FullTable.vue'
import AddModal from '../components/modals/AddUserModal.vue'
import ImportUserModal from '../components/modals/ImportUserModal.vue'

export default {
  name: 'User',
  components: {
    FullTable,
    // HeadCards,
    AddModal,
    ImportUserModal,
  },
  directives: {
    Ripple,
  },
  mixins: [toast, areYouSure],
  data() {
    return {
      isEdit: false,
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
      fields: [
        {
          label: 'ID',
          key: 'contact.id',
          sortable: true,
        },
        {
          label: 'users.full_name',
          key: 'contact.full_name',
          sortable: true,
        },
        {
          label: 'users.branchs',
          key: 'contact.branch.title',
        },
        {
          label: 'users.roles',
          key: 'roles',
        },
        {
          label: 'users.phone_number',
          key: 'phones',
        },
        {
          label: 'general.actions',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('users', ['GET_USERS_LIST']),
  },
  beforeDestroy() {
    this.SET_USERS_LIST([])
  },
  mounted() {
    this.params = this.$_getFilter()
  },
  async beforeRouteLeave(to, from, next) {
    this.$_saveFilter(this.params)
    next()
  },
  methods: {
    ...mapMutations('users', ['SET_USERS_LIST']),
    ...mapActions('users', [
      'FETCH_USERS_LIST',
      'DELETE_USER',
      'EXPORT_USERS_FROM_FILE',
    ]),
    addUser() {
      this.$bvModal.show('AddModalUser')
    },
    refresh() {
      this.FETCH_USERS_LIST(this.params)
    },
    importUsers() {
      this.$bvModal.show('import-user-modal')
    },
    downloadFile(res) {
      const blobUrl = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a') // Or maybe get it from the current document
      link.href = blobUrl
      link.setAttribute('download', `${Date.now()}.xls`)
      document.body.appendChild(link)
      link.click()
    },
    exportUsers(params) {
      console.log(params)
      this.EXPORT_USERS_FROM_FILE(params)
        .then(res => {
          console.log(res)
          this.downloadFile(res.data)
        })
        .catch(() => {
          this.$_errorToast(this.$t('general.error'))
        })
    },
    modalDeleteUser(id, params) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_USER(id)
            .then(() => {
              this.$_successToast(this.$t('general.removed'))
              this.FETCH_USERS_LIST(params)
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
  },
}
</script>
